
import { defineComponent } from "vue";
import SearchBar from "@/components/SearchBar.vue";
import NoDate from "@/components/NoData.vue";
import { StationService } from "@/services/attendance/station/StationService";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { useRoute } from "vue-router";
import Utils from "@/utils/Utils";
import { debug } from "console";
import router from "@/router";
import moment from "moment";
export default defineComponent({
  name: "AttPlaceUserList",
  components: {
    SearchBar,
    NoDate,
    CardLoading,
  },
  data() {
    return {
      today: moment().format("yyyy-MM-dd"), //今天
      service: new StationService(),
      router: router,
      useRoute: useRoute(),
      list: [],
      islist: true,
      hasMore: true, //是否有更多数据
      showNoDate: true, //展示暂无数据
      selectIds: new Array<String>(), //选中Id集合
      searchParams: {
        size: 10,
        page: 1,
        keyword: "",
        placeId: "",
        approveStatus: "1",
      },
    };
  },
  mounted() {
    if (this.useRoute.query.placeId != null) {
      this.searchParams.placeId = this.useRoute.query.placeId as string;
      this.init();
    }
  },
  methods: {
    getStaffDate(item: any) {
      if (item.endDate) {
        return item.startDate + " 至 " + item.endDate;
      }
      return "无";
    },
    handelCheckBox(ev: CustomEvent, id: string) {
      if (ev.detail.checked) {
        this.selectIds.push(id);
      } else {
        const index = this.selectIds.findIndex((o) => {
          return o == id;
        });
        this.selectIds.splice(index, 1);
      }
    },
    back() {
      this.router.go(-1);
    },
    //搜索框查询
    searchHandler(searchParam: any) {
      this.searchParams.keyword = searchParam.searchKey;
      this.init();
    },
    formatName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    segmentChanged(ev: any) {
      this.searchParams.approveStatus = ev;
      this.init();
    },
    //重新从第一页获取
    init() {
      this.list = [];
      this.selectIds = [];
      this.searchParams.page = 1;
      this.handelGetList((res: any) => {
        this.list = res;
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    //刷新
    doRefresh(event: any) {
      this.init();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页
    nextPage(event: any) {
      if (!this.hasMore) {
        event.target.complete();
        return;
      }
      this.searchParams.page++;
      this.handelGetList((result: any) => {
        if (result) {
          this.list = this.list.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //请求数据
    handelGetList(callback: any) {
      this.service
        .getUserList(this.searchParams)
        .then((res: any) => {
          this.islist = false;
          if (res.length < this.searchParams.size) {
            this.hasMore = false;
          }
          res.map((o: any) => {
            o.status = 1;
            if (o.approveStatus == 4) {
              o.status = 0;
            } else if (
              o.approveStatus == 1 &&
              o.endDate &&
              o.endDate < this.today
            ) {
              o.status = 0;
            }
          });
          this.showNoDate = res.length == 0;
          callback(res);
        })
        .catch(() => {
          this.islist = false;
        });
    },
    handleRemove() {
      if (this.selectIds.length == 0) {
        Utils.presentToastWarning("请选择用户");
        return;
      }
      this.service
        .removeUser(this.selectIds)
        .then(() => {
          this.init();
        })
        .catch(() => {
          Utils.presentToastWarning("移除失败");
        });
    },
  },
});
